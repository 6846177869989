import autosize from 'autosize/dist/autosize';

export default () => {
  const textareas = document.querySelectorAll('textarea');

  if (textareas.length === 0) {
    return;
  }

  autosize(textareas);
};
