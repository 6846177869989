export default () => {
  const header = document.querySelector('.js--header');
  const html = document.querySelector('html');

  if (!header) {
    return;
  }

  const burger = header.querySelector('.js--burger');
  const headerLinks = header.querySelectorAll('.js--header-link');
  const headerOverlay = header.querySelector('.js--header__overlay');

  function onOverlayClickHandler () {
    html.classList.remove('locked');
    header.classList.remove('active');
  }

  function burgerClickHandler() {
    if (window.innerWidth < 1280) {
      html.classList.toggle('locked');
      header.classList.toggle('active');
    } else {
      html.classList.remove('locked');
      header.classList.remove('active');
    }
  }

  function onResizeHandler() {
    if (window.innerWidth >= 1280) {
      html.classList.remove('locked');
      header.classList.remove('active');
    }
  }

  burger.addEventListener('click', burgerClickHandler);
  headerOverlay.addEventListener('click', onOverlayClickHandler);

  if (headerLinks.length === 0) {
    return;
  }

  headerLinks.forEach((link) => {
    link.addEventListener('click', burgerClickHandler);
  });

  window.addEventListener('resize', onResizeHandler);
};
