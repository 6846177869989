export default () => {
  const items = document.querySelectorAll('.js--services-item');

  if (!items) {
    return null;
  }

  items.forEach((item) => {

    function onShowButtonClickHandler() {
      item.classList.toggle('active');
    }

    const showButton = item.querySelector('.js--services__item-button-show');
    const closeButton = item.querySelector('.js--services__item-overlay-button');
    showButton.addEventListener('click', onShowButtonClickHandler);
    closeButton.addEventListener('click', onShowButtonClickHandler);
  });
};
