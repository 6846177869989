import Inputmask from 'inputmask';

export default () => {
  const forms = document.querySelectorAll('.js--form');
  const errors = {
    required: 'Поле обязательно для заполнения',
    mismatch: 'Поле заполнено неверно',
  };
  const emailMask = /^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z0-9.-]+$/i;

  if (forms.length === 0) {
    return;
  }

  forms.forEach((form) => {
    const maskedInputs = document.querySelectorAll('.js--input--phone');
    const closeSuccessButton = form.querySelector('.js--close-success');

    // Убирает блок успешной отправки
    function closeSuccessBlock() {
      form.classList.remove('success');
    }

    // Добавление успеха/ошибки полю
    function addSuccess(input) {
      input.classList.remove('error');
      if (input.value.length > 0) {
        input.classList.add('success');
      }
    }

    function addError(input, errorText = errors.mismatch) {
      const error = input.closest('.js--input').querySelector('span');
      error.textContent = errorText;
      input.classList.add('error');
      input.classList.remove('success');
    }

    function removeState(input) {
      input.classList.remove('error-filled');
      input.classList.remove('error');
      input.classList.remove('success');
    }

    // Добавление маски на поля с телефоном
    function createPhoneMask(input) {
      const phone = new Inputmask('+7 (999) 999-99-99', {
        showMaskOnHover: false,
        showMaskOnFocus: false,
      });
      phone.mask(input);
    }
    maskedInputs.forEach((input) => {
      createPhoneMask(input);
    });

    function onTelInputBlur(e) {
      const { target } = e;
      const clearValue = target.value.replace(/_/g, '');

      if (clearValue.length < 18) {
        addError(target);
      } else {
        addSuccess(target);
      }

      if (clearValue.length === 18) {
        addSuccess(target);
      }

      if (clearValue.length === 0 && !target.required) {
        removeState(target);
      }
    }

    // Добавление маски на поля с мэйлом
    function onEmailInputBlur(e) {
      const { target } = e;
      if (target.value.length === 0 && target.required) {
        addError(target, errors.required);
      } else if (target.value.length > 0 && !emailMask.test(target.value)) {
        addError(target);
      } else {
        addSuccess(target);
      }
    }

    // Проверка валидности на блюр
    function onInputBlur(e) {
      const { target } = e;
      if (target.validity.valid) {
        addSuccess(target);
      } else {
        addError(target, errors.required);
      }
    }

    // Скрытие ошибок на фокус
    function onInputFocus(e) {
      const { target } = e;
      removeState(target);
    }

    // Навешивание слушателей событий на все инпуты в зависимости от типа
    function addInputEvents(inputs) {
      inputs.forEach((input) => {
        switch (input.type) {
          case 'tel':
            createPhoneMask(input);
            input.addEventListener('blur', onTelInputBlur);
            break;
          case 'email':
            input.addEventListener('blur', onEmailInputBlur);
            break;
          default:
            input.addEventListener('blur', onInputBlur);
        }

        input.addEventListener('focus', onInputFocus);
      });
    }

    // Валидация инпутов
    function addInputValidation(inputs) {
      inputs.forEach((input) => {
        if ((input.value.length === 0 && input.required) || input.classList.contains('error')) {
          addError(input, errors.required);
        }

        if (input.type === 'email') {
          if (input.value.length === 0) {
            addError(input, errors.required);
          } else if (input.value.length > 0 && !emailMask.test(input.value)) {
            addError(input, errors.mismatch);
          } else {
            addSuccess(input);
          }
        }

        if (input.type === 'checkbox' && input.required) {
          if (!input.checked) {
            addError(input, errors.required);
          } else {
            addSuccess(input);
          }
        }

        if (input.type === 'textarea' && input.required) {
          if (input.value.length === 0) {
            addError(input, errors.required);
          } else {
            addSuccess(input);
          }
        }
      });
    }

    // Валидация формы
    function validateForm() {
      const validationInputs = form.querySelectorAll('input, textarea');
      addInputValidation(validationInputs);
      const isErrored = form.querySelectorAll('.error');
      return isErrored.length === 0;
    }

    const inputs = form.querySelectorAll('input, textarea');

    addInputEvents(inputs);

    inputs.forEach((input) => {
      input.addEventListener('keydown', (e) => {
        if (e.keyCode === 13) {
          validateForm();
          const isErrored = form.querySelectorAll('.error');
          if (isErrored.length !== 0) {
            e.preventDefault();
          }
        }
      });
    });

    form.addEventListener('invalid', (e) => {
      const validationInputs = form.querySelectorAll('input, textarea');
      e.preventDefault();
      addInputValidation(validationInputs);
    }, true);

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      if (validateForm()) {
        const email = form.querySelector('#input-email').value;
        const phone = form.querySelector('#input-phone').value;
        const name = form.querySelector('#input-name').value;

        form.style.pointerEvents = 'none';
        fetch(`http://tourpass.ru:9090/api/v1/about/application?email=${email}&name=${name}&phone=${phone}`, {
          method: 'POST',
        }).then((res) => {
          if (res.status === 200) {
            form.reset();
            form.classList.add('success');
            form.style.pointerEvents = 'auto';
          }
        });
      }
    });
    closeSuccessButton.addEventListener('click', closeSuccessBlock);
  });
};
