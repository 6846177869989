export default () => {
  const popup = document.querySelector('.js--popup')
  const popupOpenButtons = document.querySelectorAll('.js--popup-open')
  const closePopupTriggers = popup.querySelectorAll('.js--popup__close')
  const body = document.querySelector('body')

  if (!popup || popupOpenButtons.length < 1 ) {
    return null;
  }

  function onPopupOpenButtonClickHandler() {
    popup.classList.add('active');
    body.classList.add('locked')
  }

  function onPopupOverlayClickHandler() {
    popup.classList.remove('active');
    body.classList.remove('locked')
  }

  popupOpenButtons.forEach((button) => {
    if (!button.classList.contains('init')) {
      button.addEventListener('click', onPopupOpenButtonClickHandler)
      button.classList.add('init')
    }
  });

  if (closePopupTriggers.length > 0) {
    closePopupTriggers.forEach(buttonClose => {
      if (!buttonClose.classList.contains('init')) {
        buttonClose.addEventListener('click', onPopupOverlayClickHandler)
        buttonClose.classList.add('init')
      }
    });
  }
}
