export default () => {
  const anchorLinks = document.querySelectorAll('.js--anchor-link');
  const targets = document.querySelectorAll('.js--smooth-target[data-name]');

  if (anchorLinks.length === 0) {
    return;
  }

  function getTop(elem) {
    const box = elem.getBoundingClientRect();
    const boxTop = box.top + window.pageYOffset;
    return boxTop;
  }

  function onLinkClick(evt) {
    const { target } = evt;
    const item = target.closest('.js--anchor-link');

    if (item) {
      evt.preventDefault();

      const targetName = item.getAttribute('data-target');
      for (let t = 0; t < targets.length; t += 1) {
        if (targets[t].getAttribute('data-name') === targetName) {
          window.scrollTo({
            top: getTop(targets[t]), behavior: 'smooth',
          });
          window.history.pushState(null, null, `#${targetName}`);
        }
      }
    }
  }

  anchorLinks.forEach((link) => {
    link.addEventListener('click', onLinkClick);
  });
};
