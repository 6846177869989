import 'focus-visible';
import header from './modules/header';
import smooth from './modules/smooth';
import services from './modules/services';
import form from './modules/form';
import autoSize from './modules/autoSize';
import popup from './modules/popup';

require('fslightbox');

header();
smooth();
services();
form();
autoSize();
popup();

window.popupInit = popup;
window.inputInit = form;
